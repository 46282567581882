import './App.css';
import React, { useState, useEffect } from 'react';
import GITLogo from './GIT-Logo-Transparent-Background.png';
import { FcInfo } from 'react-icons/fc';


const ownerOptions = ["EVAPCO", "Customer/Client", "Individual", "Public"];
const typeOfDataOptions = ["Administrative", "Contractor/Customer Sensitive", "Evidentiary/Investigative", "Financial","Intellectual Property", "Marketing/Communications", "Medical/Protected Health", "Other", "Personnel", "Privacy", "Proprietary", "Public Trust Classified", "Security Management"];
const individuallyIdentifiableOptions = ["Yes", "No"];
const consequencesOptions = ["Low", "Medium", "High"];
const intendedUseOptions = ["Public Communication", "Client Reporting", "Business Development", "Strategic Purposes", "Historical Record"];

const InfoTool = () => {
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [selectedTypeOfData, setSelectedTypeOfData] = useState(null);
  const [selectedIndividuallyIdentifiable, setSelectedIndividuallyIdentifiable] = useState(null);
  const [selectedConsequences, setSelectedConsequences] = useState(null);
  const [selectedIntendedUse, setSelectedIntendedUse] = useState(null);
  const [result, setResult] = useState("Please select the type of data.");

  const handleOwnerChange = (e) => { setSelectedOwner(e.target.value); };
  const handleTypeOfDataChange = (e) => { setSelectedTypeOfData(e.target.value); evaluateResult(); };
  const handleSelectedIndividuallyIdentifiableChange = (e) => { setSelectedIndividuallyIdentifiable(e.target.value); };
  const handleSelectedConsequencesChange = (e) => { setSelectedConsequences(e.target.value); };
  const handleSelectedIntendedUseChange = (e) => { setSelectedIntendedUse(e.target.value); };
  const resetTable = () => {
    setSelectedOwner(null);
    setSelectedTypeOfData(null);
    setSelectedIndividuallyIdentifiable(null);
    setSelectedConsequences(null);
    setSelectedIntendedUse(null);
  }
  const getHelp = () => {
    window.open("https://evapco.atlassian.net/wiki/spaces/GSD/pages/1084129282/How+to+Use+GIT+Information+Classification+Tool", "blank", "infoclass.evapco.com");
  }
  const evaluateResult = () => {
    if (selectedTypeOfData === "Other") {
      switch (selectedOwner) {
        case "EVAPCO":
          if (selectedIntendedUse === "Historical Record") {
            setResult("Internal Use Only");
          } else if (selectedIntendedUse === "Public Communication") {
            setResult("Public");
          } else if (selectedConsequences === null) {
            setResult("Please select the consequences of the data.");
          } else if (selectedConsequences === "High") {
            setResult("Confidential");
          } else if (selectedConsequences === "Medium") {
            setResult("Confidential");
          } else if (selectedConsequences === "Low") {
            setResult("Internal Use Only");
          } else {
            setResult("Confidential");
          } break;
        case "Customer/Client":
          if (selectedConsequences === null) {
            setResult("Please select the consequences of the data.");
          } else if (selectedConsequences === "Low") {
            setResult("Internal Use Only");
          } else {
            setResult("Confidential");
          } break;
        case "Individual":
          if (selectedConsequences === null) {
            setResult("Please select the consequences of the data.");
          } else if (selectedConsequences === "High") {
            setResult("Confidential");
          } else if (selectedConsequences === "Medium") {
            setResult("Internal Use Only");
          } else {
            setResult("Public");
          } break;
        case "Public":
          setResult("Public"); break;
        default:
          setResult("Please select the owner of the data.");
      }
    } else {
      switch(selectedTypeOfData) {
        case "Administrative":
          setResult("Internal Use Only"); break;
        case "Contractor/Customer Sensitive":
          setResult("Confidential"); break;
        case "Evidentiary/Investigative":
          setResult("Confidential"); break;
        case "Financial":
          if (setSelectedIndividuallyIdentifiable === "Yes") {
            setResult("Restricted");
          } else {
            setResult("Confidential");
          } break;
        case "Intellectual Property":
          setResult("Confidential"); break;
        case "Marketing/Communications":
          if (selectedIntendedUse === null) {
            setResult("Please select the intended use of the data.");
          } else if (selectedIntendedUse === "Public Communication") {
            setResult("Public");
          } else if (selectedIntendedUse === "Historical Record") {
            setResult("Internal Use Only");
          } else {
            setResult("Confidential");
          } break;
        case "Medical/Protected Health":
          if (selectedIndividuallyIdentifiable === null) {
            setResult("Please select whether the data is individually identifiable.");
          } else if (selectedIndividuallyIdentifiable === "Yes") {
            setResult("Restricted");
          } else {
            setResult("Internal Use Only");
          } break;
        case "Personnel":
          setResult("Confidential"); break;
        case "Privacy":
          setResult("Confidential"); break;
        case "Proprietary":
          setResult("Confidential"); break;
        case "Public Trust Classified":
          setResult("Restricted"); break;
        case "Security Management":
          setResult("Confidential"); break;
        default:
          setResult("Please select the type of data."); 
      }
    }
  }

  function getColorFromResult(result) {
    switch(result) { // Based on colors in Sensitivity Labeling Scheme
      case "Public":
        return "#7FBA00"; // MS Green
      case "Internal Use Only":
        return "#EAA221"; // MS Marigold
      case "Confidential":
        return "#F25022"; // MS Orange
      case "Restricted":
        return "#800020"; // MS Burgundy
      default:
        return "#F0ECEC";
    }
  }

  useEffect(() => {
    evaluateResult();
  }, [selectedOwner, selectedTypeOfData, selectedIndividuallyIdentifiable, selectedConsequences, selectedIntendedUse]);

  return (
    <div className='page-container'>
      <table className="result-table">
        <thead>
          <tr>
            <td style={{backgroundColor: getColorFromResult(result)}}>
              <b style={{color: result === "Restricted" ? "white" : "black"}}>{result}</b>
            </td>
          </tr>
        </thead>
      </table>
      <div className="header">
        <img src={GITLogo} alt="GIT Logo" class='GITLogo'/>
        <h1>EVAPCO Information Classification Tool</h1>
      </div>
      <div class="table-container">
        <div class="table-buttons-container">
          <button className="help-button" onClick={getHelp}>Need Help?</button>
          <button className="table-button" onClick={resetTable}>Reset</button>
        </div>
          <table className="table-body">
          <thead>
            <tr className="table-header">
              <th>Type of Data<span class="info-container">
                <span class="info-icon"><FcInfo/></span>
                <span class="info-text">A specific category of information defined<br/>by an organization, or in some instances,<br/>by a specific law, Executive Order, directive,<br/>policy, or regulation.</span></span>
              </th>
              <th>Owner<span class="info-container">
                <span class="info-icon"><FcInfo/></span>
                <span class="info-text">Ownership rights to control or custodial<br/>obligation to preserve entrusted data.</span></span>
              </th>
              <th>Individually Identifiable<span class="info-container">
                <span class="info-icon"><FcInfo/></span>
                <span class="info-text left">Includes or indicates a specific identity<br/>or the corresponding identity could<br/>reasonably be inferred.</span></span></th>
              <th>Consequences<span class="info-container">
                <span class="info-icon"><FcInfo/></span>
                <span class="info-text left">Existence and severity of impact associated with breach.</span></span>
              </th>
              <th>Intended Use<span class="info-container">
                <span class="info-icon"><FcInfo/></span>
                <span class="info-text left">The manner or nature of the authorized business<br/>purpose for which the asset is to be employed.</span></span>
              </th>
              </tr>
            </thead>
            <tbody>
              {typeOfDataOptions.map((option, index) => (
                <tr key={option}>
                  <td>
                    <label>
                      <input
                        type="radio"
                        name="typeOfData"
                        value={typeOfDataOptions[index]}
                        checked={selectedTypeOfData === typeOfDataOptions[index]}
                        onChange={handleTypeOfDataChange}
                      />
                      {option === "Medical/Protected Health" ? (
                        <>Medical/Protected Health
                        <span class="info-container">
                        <span class="info-icon"><FcInfo/></span>
                        <span class="info-text right">
                          1.  Names<br/>
                          2.  Geographical Subdivisions<br/>
                          3.  Dates<br/>
                          4.  Phone Numbers<br/>
                          5.  Fax Numbers<br/>
                          6.  Email Address<br/>
                          7.  Social Security Numbers<br/>
                          8.  Medical Record Numbers<br/>
                          9.  Beneficiary Numbers<br/>
                          10. Account Numbers<br/>
                          11. Certificate/License Numbers<br/>
                          12. Vehicle Identifiers<br/>
                          13. Device Identifiers<br/>
                          14. URLs<br/>
                          15. IP Address<br/>
                          16. Biometric Identifiers<br/>
                          17. Facial Photograph<br/>
                          18. Any other Unique ID</span></span>
                          </>
                      ) : (option)}
                    </label>
                  </td>
                  <td>
                    {ownerOptions[index] && (
                      <label>
                        <input
                          type="radio"
                          name="owner"
                          value={ownerOptions[index]}
                          checked={selectedOwner === ownerOptions[index]}
                          onChange={handleOwnerChange}
                          />
                        {ownerOptions[index]}
                      </label>
                    )}
                  </td>
                  <td>
                    {individuallyIdentifiableOptions[index] && (
                      <label>
                        <input
                          type="radio"
                          name="individuallyIdentifiable"
                          value={individuallyIdentifiableOptions[index]}
                          checked={selectedIndividuallyIdentifiable === individuallyIdentifiableOptions[index]}
                          onChange={handleSelectedIndividuallyIdentifiableChange}
                        />
                        {individuallyIdentifiableOptions[index]}
                      </label>
                    )}
                  </td>
                  <td>
                    {consequencesOptions[index] && (
                      <label>
                        <input
                          type="radio"
                          name="consequences"
                          value={consequencesOptions[index]}
                          checked={selectedConsequences === consequencesOptions[index]}
                          onChange={handleSelectedConsequencesChange}
                        />
                        {(() => {
                          switch (consequencesOptions[index]) {
                            case 'Low':
                              return (
                                <>Low
                                <span class="info-container">
                                  <span class="info-icon"><FcInfo/></span>
                                  <span class="info-text left">Low -- Almost no impact if this information<br/>were to be disclosed without authorization.</span>
                                </span>
                                </>
                              );
                            case 'Medium':
                              return (
                                <>Medium
                                <span class="info-container">
                                  <span class="info-icon"><FcInfo/></span>
                                  <span class="info-text left">Medium -- The effect of unauthorized disclosure<br/>would be of modest embarassment and/or loss<br/>of customer confidence or reputation but does<br/>not carry any formal liability or financial penalties.</span>
                                </span>
                                </>
                              );
                             case 'High':
                              return (
                                <>High
                                <span class="info-container">
                                  <span class="info-icon"><FcInfo/></span>
                                  <span class="info-text left">High -- Unauthorized disclosure will definitely<br/>result in notable loss of goodwill, incurs<br/>formal liability, results in breach of a contract,<br/>or subjects the organization to civil and/or<br/>criminal penalties.</span>
                                </span>
                                </>
                              );
                            default:
                              return consequencesOptions[index];
                          }
                        })()}
                      </label>
                    )}
                  </td>
                  <td>
                    {intendedUseOptions[index] && (
                      <label>
                        <input
                          type="radio"
                          name="intendedUse"
                          value={intendedUseOptions[index]}
                          checked={selectedIntendedUse === intendedUseOptions[index]}
                          onChange={handleSelectedIntendedUseChange}
                        />
                        {intendedUseOptions[index]}
                      </label>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
      </div>
    </div>
  )
}

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <div className="footer">
        <p>&copy; {currentYear} EVAPCO, Inc.</p>
      </div>
    </footer>
  );
}

function App() {
  return (
    <div className="App">
      <InfoTool />
      <Footer />
    </div>
  );
}

export default App;
